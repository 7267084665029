import 'tailwindcss/tailwind.css';
import '../../styles/index.css';
import React, {useEffect} from 'react';
import Head from 'next/head';
import {AppProps} from 'next/app';
import {toast, Toaster} from 'sonner';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {SWRConfig} from 'swr';
import {network} from '../lib/network';
import {DefaultSeo} from 'next-seo';
import Script from 'next/script';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

/**
 * Main app component
 * @param props Props
 * @param props.Component App component
 * @param props.pageProps
 */
function App({Component, pageProps}: AppProps) {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const message = searchParams.get('message');

    if (message) {
      toast(message, {duration: 10000});
    }
  }, []);

  return (
    <>
      <Head>
        <title>Plunk | The Open-Source Email Platform</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" key={'viewport'} />
      </Head>
      <Toaster position={'top-right'} />

      <Component {...pageProps} />
    </>
  );
}

/**
 * Main app root component that houses all components
 * @param props Default nextjs props
 */
export default function WithProviders(props: AppProps) {
  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => network.fetch('GET', url),
        revalidateOnFocus: true,
      }}
    >
      <DefaultSeo
        defaultTitle={'Plunk | The Open-Source Email Platform'}
        title={'Plunk | The Open-Source Email Platform'}
        description={
          'Plunk is the open-source, affordable email platform that brings together marketing, transactional and broadcast emails into one single, complete solution'
        }
        twitter={{cardType: 'summary_large_image', handle: '@useplunk', site: '@useplunk'}}
        openGraph={{
          title: 'Plunk | The Open-Source Email Platform',
          description:
            'Plunk is the open-source, affordable email platform that brings together marketing, transactional and broadcast emails into one single, complete solution',
          images: [{url: 'https://www.useplunk.com/assets/card.png', alt: 'Plunk'}],
        }}
        additionalMetaTags={[{property: 'title', content: 'Plunk | The Open-Source Email Platform'}]}
      />

      <Script
        defer
        type="text/javascript"
        src="https://analytics.driaug.com/script.js"
        id="umami-analytics"
        data-website-id="d48cff1b-2f74-4225-984f-482ef3560f4e"
      ></Script>

      <Script
        strategy={'afterInteractive'}
        id={'index-clarity'}
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "civcistir8");`,
        }}
      />

      <Script
        strategy={'afterInteractive'}
        id={'index-crisp'}
        dangerouslySetInnerHTML={{
          __html: `
        window.$crisp=[];window.CRISP_WEBSITE_ID="37d1bd45-6d56-48bd-9413-7c429ace3ca5";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
        `,
        }}
      />

      <App {...props} />
    </SWRConfig>
  );
}
